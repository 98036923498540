import { apiService } from "../../services";
import { TeamModel } from "outbound-api-client/lib/models";

export const getMyTeam = async (): Promise<TeamModel | null> => {
  if (apiService.instance.isLoggedIn())
  {
    let myTeams = await apiService.instance.getMyTeams();

    let returnValue: TeamModel | null = null;

    if (myTeams.length === 1)
    {
      returnValue = myTeams[0];
    }
    
    return returnValue;
  }
  else
  {
    return null;
  }
}
