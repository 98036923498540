import styled from "styled-components";
import { HeaderMenuProps } from "./Header.types";

export const HeaderBar = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  margin: 0;
  background-color: ${props => props.theme.Header.BackgroundColour};
  padding: 10px 0;
  border-radius: 0;
  padding-left: ${props => props.theme.Grid.GutterSize};
  padding-right: ${props => props.theme.Grid.GutterSize};
  flex-shrink: 0;
  z-index: 1;
  
  @media (min-width: ${props => props.theme.Grid.Breakpoints.SM}px) {
    position: static;
    margin-bottom: ${props => props.theme.Grid.GutterSize};
    border-radius: ${props => props.theme.Base.BorderRadius};
  }
`

export const ProfileContainer = styled.div`
  max-width: 120px;
  display: flex;
  align-items: center;

  @media (min-width: ${props => props.theme.Grid.Breakpoints.MD}px) {
    display: none;
  }
`

export const HeaderMenu = styled.div<HeaderMenuProps>`
  position: absolute;
  top: 100%;
  ${props => props.position === 'right' ? 'right: 0;' : 'left: 0;'}
  z-index: 1;
  overflow: visible;
  width:100%;

  @media (min-width: ${props => props.theme.Grid.Breakpoints.SM}px) {
    top: calc(100% - 1em);
    width: auto;
  }

  &:after{
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 6px 7px 6px;
    border-color: transparent transparent ${props => props.theme.HighlightTextColour} transparent;
    top: -7px;
    ${props => props.position === 'right' ? 'right: calc(1em + 1em - 3.5px);' : 'left: calc(1em + 6px);'}
    
  }
`