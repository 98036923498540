import styled from "styled-components";
import { IconTextProps, UserIconProps } from "./UserPicture.types";

export const UserIcon = styled.div<UserIconProps>`
  width: ${props => props.size || 2.5}rem;
  height: ${props => props.size || 2.5}rem;
  border-radius: ${props => props.size || 2.5}rem;
  background: ${props => props.background};
  margin-right: 0.5rem;
  display: block;
  color: ${props => props.theme.HighlightTextColour};
  text-align: center;
  line-height: ${props => props.size || 2.5}rem;
`
export const UserIconText = styled.span.attrs<IconTextProps>(props => ({
  className: props.useLargerFont ? 'font-title m-y-0 font-style-regular' : 'font-body m-y-0',
}))<IconTextProps>`
  user-select: none;
`;