import React from "react";
import { Card } from "../../../components";
import { AssetModel } from "outbound-api-client/lib/models";
import { AssetLink } from "./Asset.styles";
import { apiService, configService } from "../../../services";
import { faDownload, faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Asset: React.FC<AssetModel> = ({
  name,
  description,
  uri,
  thumbnail
}) => {

  const onClick = async () => {
    let token = await apiService.instance.checkToken("");
    window.open(`${uri.split("?")[0]}?access_token=${token.access_token}`);
  }

  return (
    <>

      <AssetLink onClick={onClick} target="_blank" rel="noreferrer">
        <Card headerImg={thumbnail} contentCSS='p-bottom-05'>
          <div className="asset-details-container">
            <h3 className='font-heading'>{name}</h3>
            <p className='font-subheading'>{description}</p>
          </div>
        </Card>
        {(configService.current().enableProps?.downloadIcons) ? (uri.indexOf(".mp4") == -1) ?
          <FontAwesomeIcon size="2x" color={configService.current().colours.BLACK} icon={faDownload} style={{width: "25px",minWidth: "25px"}} /> :
          <FontAwesomeIcon size="2x" color={configService.current().colours.BLACK} icon={faPlay} style={{width: "25px",minWidth: "25px"}} />
         :<></>}
      </AssetLink>

    </>
  )
}