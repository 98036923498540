import styled from "styled-components";
import { hexToRgb } from "../../style/helpers";

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: ${props => props.theme.Card.BorderRadius};
  background-color: ${props => props.theme.Card.BackgroundColor};
  margin-bottom: ${props => props.theme.Card.ContentMargin};
  
  ${({theme}) => {
    if (theme.Card.HasDropShadow) {
      return `
        box-shadow: ${theme.Card.DropShadowPlacement} rgba(${hexToRgb(theme.Card.DropShadowColor)},${theme.Card.DropShadowAlpha});
      `;
    }
  }}

  img {
    border-radius: ${props => props.theme.Card.BorderRadius};
    width: 100%;
    height: auto;
  }
`;

export const CardContent = styled.div`
  padding-left: ${props => props.theme.Card.ContentPadding};
  padding-right: ${props => props.theme.Card.ContentPadding};
`;