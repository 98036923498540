import React from "react";
import { ContentTemplateModel } from "outbound-api-client/lib/models";
import { Content } from "../../../components";

export const ContentTemplate: React.FC<ContentTemplateModel> = ({
  title,
  summary,
  template,
}) => {

  return (
    <>
      <div className='p-x-1'>
        <h3 className='font-title text-center'>{title}</h3>
        <p className='font-subtitle'>{summary}</p>
        <Content template={template} />
      </div>
    </>  
  )
}