import styled from "styled-components";
import { configService } from '../../services';
import { splitValueFromUnit } from "../../style/helpers";
import { CarouselButtonProps } from "./Carousel.types";

export const CarouselButton = styled.button.attrs<CarouselButtonProps>(props => ({
  type: 'button',
  onClick: props.onClick,
  className: props.className,
}))<CarouselButtonProps>`
  appearance: none;
  padding: 0;
  margin: 0;
  outline: none;
  border: none;
  width: 2.5em;
  height: 2.5em;
  position: absolute;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
  line-height: auto;
  font-size: inherit;
  top: 50%;
  transform: translateY(-100%);
  transition: all ${props => props.theme.AnimationTimingFast} linear;
  color: inherit;
  z-index: 1;

  &:focus {
    background: #fff;
    color: #000;
  }

  &:hover {
    background: ${configService.current().colours.GREY_LIGHT};
  }

  &.slick-prev {
    left: initial;
    right: 2.75em;
  }

  &.slick-disabled {
    opacity: 0.5;
    &:hover {
      background: #fff;
    }

    &:before {
      opacity: 1
    }
  }

  &.slick-next:before {
    display: inline-block;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    content: "\f054";
    color: #000;
  }

  &.slick-prev:before {
    display: inline-block;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    content: "\f053";
    color: #000;
  }
`;

export const CarouselItem = styled.div`
  padding-left: ${props => {
    const {value, unit} = splitValueFromUnit(props.theme.Grid.GutterSize);

    return `${value/2}${unit}`;
  }};
  padding-right: ${props => {
    const {value, unit} = splitValueFromUnit(props.theme.Grid.GutterSize);

    return `${value/2}${unit}`;
  }};
  padding-bottom: ${props => props.theme.Grid.GutterSize};
  height: 100%;
`