import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { LogDealActionBarContainer } from './DealSummary.styles';

export const LogDealActionBar: React.FC = () => {

    const { campaignId } = useParams();

    return (
        <LogDealActionBarContainer id='hello'>
            <Link to={`/incentive/${campaignId}/dealreg`}>LOG NEW DEAL</Link>
        </LogDealActionBarContainer>
    );
}