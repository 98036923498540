export const getDateString = (date: Date | null) => {

    if (date == null)
      return null;

  const yyyy = date.getFullYear();
  const m = (date.getMonth() + 1).toString();
  const d = date.getDate().toString();

  return d.padStart(2, '0') + '/' + m.padStart(2, '0') + '/' + yyyy;
}

export const getDateStringForDateField = (date: Date | null) => {

  if (date == null)
      return null;
      
  const yyyy = date.getFullYear();
  const m = (date.getMonth() + 1).toString();
  const d = date.getDate().toString();

  return yyyy + '-' + m.padStart(2, '0') + '-' + d.padStart(2, '0');
}