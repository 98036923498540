import React from 'react';
import { Link } from 'react-router-dom';
import { EmailTemplateContainer, EmailTemplateImageContainer } from './Emailer.styles';


interface IProps {
    templateId: string;
    thumbnailUrl: string;
    name: string;
}

export const EmailTemplate: React.FC<IProps> = ({
    templateId,
    thumbnailUrl,
    name
  }) => {

    return (
    <EmailTemplateContainer>
        <EmailTemplateImageContainer>
            <img src={thumbnailUrl} />
        </EmailTemplateImageContainer>
        <div><Link to={`/send/${templateId}`}>{name}</Link></div>
    </EmailTemplateContainer>
    );
}