import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Row, Cell, Card, Thumbnail, UserCard, LoaderAnimated } from '../components';
import { useLeaderboard, useCampaign } from '../modules';
import { TrainingNavButton } from '../modules/Training/components/Training.styles';
import { useAppSelector } from '../store';
import { selectUserStatus } from '../store/slices/userStatus.slice';
import { configService } from '../services';

export const IncentiveLeaderboard: React.FC = () => {
  const { campaignId } = useParams();
  const navigate = useNavigate();
  const { campaign, hasError, isLoading } = useCampaign(campaignId || '');
  const {scores} = useLeaderboard(campaignId);
  const userStatus = useAppSelector(selectUserStatus);
  const bannerUris = campaign?.contentUris.filter(c => /banner/i.test(c.identifier)) ?? [];


  const onPrevClick = () => {
    navigate(-1);
  }

  return (
    <div>
      <Helmet>
        <title>{configService.current().applicationTitle} - Incentive - Leaderboard</title>
      </Helmet>
      <div className='p-y-1'>
        <Row>
          <Cell>
            <TrainingNavButton onClick={onPrevClick}>
              <FontAwesomeIcon icon={faChevronLeft} />
              <span className='font-body m-0'>Back</span>
            </TrainingNavButton>
          </Cell>
        </Row>
      </div>
      {(!campaign || isLoading) && !hasError ? (
        <LoaderAnimated />
      ) : campaign ? (
        <Card cssClasses='m-x-0' contentCSS='p-x-0 p-bottom-2'>
          <div className='p-bottom-1'>
            <Thumbnail contentUris={bannerUris.length ? bannerUris : campaign.contentUris} altText={campaign.title??""} />
          </div>
          <div className='p-x-1'>
            <div className='d-flex justify-content-center w-100'>
              <table className='leaderboard' cellPadding={0} cellSpacing={0}>
                <thead className='text-center'>
                  <tr>
                    <th>Pos.</th>
                    <th>Member</th>
                    <th>Score</th>
                  </tr>
                </thead>
                <tbody>
                  {scores.map((s, inx) => (
                    <tr key={inx} className={s.Username === userStatus?.user ? 'highlight' : undefined}>
                      <td className='text-center'>
                        {inx + 1}
                      </td>
                      <td>
                        <UserCard key={inx} Name={s.Name} CurrentLevel={s.CurrentLevel} Highlight={s.Username === userStatus?.user} CssClass='m-bottom-0'  />
                      </td>
                      <td className='text-center'>
                        {s.Score}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </Card>
      ) : (
        <div><p>Error</p></div>
      )}
    </div>
  )
}