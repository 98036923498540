import styled from "styled-components";

export const EmailTemplateLibraryContainer = styled.div`

display: flex;
flex-wrap: wrap;
gap: 5px;
background-color: #c3c3c3;

`

export const EmailTemplateContainer = styled.div`

    flex-grow: 1;
	flex-basis: 33%;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    & a {
        color: #000;
        text-decoration: none;
    }

    & a:hover {
        text-decoration: underline;
    }


`

export const EmailTemplateImageContainer = styled.div`

& > img {
    max-height: 300px;
    border: 1px solid #c3c3c3;
}

`

export const ExistingSendsTable = styled.table`

& svg {
    margin-left:5px;
}

& .logo-container {
    display: flex;
    align-items: center;
    justify-content: center;

    & img {margin: 5px;}
}

& .row-HP {
    background-color: #40DEFA;
}

& .row-HPE {
    background-color: #01A982;
}

& img {
    max-width: 50px;
}
    
`

export const EmailTemplateSectionContainer = styled.div`

    border: 2px solid white;
    border-radius: 25px;
    padding: 50px;
    background-color: #c3c3c3;
    
`