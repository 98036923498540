import styled from "styled-components";
import { Link } from "react-router-dom";
import { AnswerProps, TrainingStatusProps } from "../Training.types";
import { GetLetterFromAnswerNumber } from "../Training.services";
import { configService } from "../../../services";

export const QuizLink = styled(Link)`
  display: inline-block;
  text-decoration: none;
  color: ${props => props.theme.TextColour};
  height: 100%;
`

export const QuizTileWrapper = styled.div`
  padding-bottom: ${props => props.theme.Grid.GutterSize};
  height: 100%;
` 

export const AnswerBtn = styled.button.attrs(() => ({
  type: 'button',
}))<AnswerProps>`
  appearance: none;
  margin: 0;
  padding: 0.5em 0.75em 0.5em 2.5em;
  margin-bottom: 1.1em;
  border-radius: 1000px;
  width: 280px;
  height: auto;
  outline: none;
  border-width: 1px;
  border-style: solid;
  border-color: #000;
  background: transparent;
  cursor: pointer;
  position: relative;
  transition-delay: 0s;
  display: inline-block;
  color: #000;

  &.selected {
    background: ${props => props.isCorrect ? configService.current().colours.TURQUOISE : configService.current().colours.RED_LIGHT};
    border-color: ${props => props.isCorrect ? configService.current().colours.TURQUOISE : configService.current().colours.RED_LIGHT};
    color: ${props => props.theme.HighlightTextColour};
    transition: all 0.3s ease-in-out;
    
    &:before {
      opacity: 0;
      pointer-events: none;
      transition: all 0.3s ease-in-out;
    }

    &:after {
      opacity: 1;
      pointer-events: all;
      transition: all 0.3s ease-in-out;
    }
  }

  &:before, &:after {
    content: '${props => GetLetterFromAnswerNumber(props.number)}';
    color: #000;
    width: 1.75em;
    height: 1.75em;
    text-align: center;
    position: absolute;
    left: 0.5em;
    top: 50%;
    transform: translateY(-50%);
    transition-delay: 0s;
    line-height: 1.74;
    border: 1px solid #000;
    border-radius: 100%;
    opacity: 1;
  }

  &:after {
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    content: "${props => props.isCorrect ? '\f00c' : '\f00d' }";
    opacity: 0;
    pointer-events: none;
    border: 1px solid #fff;
    color: #fff;
    line-height: 1.75;
  }
`

export const TrainingHeader = styled.div`
  padding-top: ${props => props.theme.Grid.GutterSize};
  padding-bottom: ${props => props.theme.Grid.GutterSize};
`;

export const TrainingNavButton = styled.button.attrs(() => ({
  type: 'button'
}))`
  appearance: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  background: none;
  color: ${props => props.theme.TextColour};
  transition: opacity 0.3s ease-in-out;

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  & > svg:first-child {
    margin-right: 0.5em;
  }

  & > svg:last-child {
    margin-left: 0.5em;
  }
`;

export const TrainingContent = styled.div`
  padding-top: 0.5em;
  padding-bottom: 5em;
`

export const TrainingStatus = styled.div<TrainingStatusProps>`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-radius: ${props => props.theme.Base.BorderRadius};
  background: rgba(0,0,0,0.66);
  height: ${props => props.status === 'open' ? '0' : '100%'};
  display: flex;
  justify-content: center;
  align-items: center;
`