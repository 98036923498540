import APIClient from 'outbound-api-client';
import { EmailTemplateParam, InteractionDataModel, InteractionEventType } from 'outbound-api-client/lib/models';
import { getIPAddress } from '.';
import { configService } from '../services';

class ApiService {
  private readonly apiClient: APIClient;

  constructor() {
    this.apiClient = new APIClient(configService.current().apiUri, configService.current().clientId, configService.current().clientSecret, `${configService.current().appUri}/`);
  }

  async postInteraction(eventType: InteractionEventType, eventIdentifier: string, eventValue: string | null = null, campaignIdentifier: string|null = null, score = 0, tags: string[] = [], data: EmailTemplateParam[] = []) {
    const ipAddress = await getIPAddress();
    const interaction = new InteractionDataModel(eventType, eventIdentifier, eventValue, new Date(), campaignIdentifier, ipAddress, score, tags, data);

    await this.apiClient.createInteraction(interaction);
  }

  async postInteractionWebForm(eventIdentifier: string, data: EmailTemplateParam[] = [], resultsEmailAddress: string) {
    const ipAddress = await getIPAddress();
    await this.apiClient.createInteractionWebForm(eventIdentifier, data, [resultsEmailAddress], ipAddress);
  }
  
  get instance() {
    return this.apiClient;
  }
}

export const apiService = new ApiService();
