import { getIn } from 'formik';
import React from 'react';
import { SelectInputProps } from './SelectInput.types';

export const SelectInput:React.FC<SelectInputProps> = ({
  field,
  form: { touched, errors },
  values,
  ...props
}) => {

  const error = getIn(errors, field.name);
  const touch = getIn(touched, field.name);

  return (
    <div>
      <select {...field}>
        {values.map((opt) => (
          <option key={opt.identifier} value={opt.value}>{opt.label}</option>
        ))}
      </select>
      {touch && error && (<div className="error">{error}</div>)}
    </div>
  );
}