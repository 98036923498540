import React from 'react';
import { Cell, Row } from '../../../components';
import { AssetWrapper } from '../../Assets/components/Asset.styles';
import { FeedItemGroup } from '../Feed.types';
import { FeedItem } from './Feed.component';

export const FeedItemCollection: React.FC<FeedItemGroup> = ({
  FeedItems,
}) => {


  return (
    <section>
      <div className="w-100">
          <Row>
            {FeedItems.map((a, inx) => (
              <Cell key={inx} baseSize={6} SMSize={4} MDSize={6} LGSize={4} XLSize={3}>
                <AssetWrapper>
                  <FeedItem {...a} key={inx} />
                </AssetWrapper>
              </Cell>
            ))}
          </Row>
      </div>
    </section>
  )
}