import styled from "styled-components";

export const SpinTheWheelContainer = styled.div`
background-image: url("/images/wheel_back.png");
background-position: center;
background-repeat: no-repeat;
display: flex;
align-items: center;
justify-content: center;
padding: 50px;
`