import { ColoursGT23 } from "../style/colours.gt23";
import { GT23Theme } from "../style/theme.gt23";
import { ConfigProps, LayoutType } from "./config.service.types";

export const JabraPromotionClaimConfig: ConfigProps = {
  appUri: `${process.env.REACT_APP_URL}`,
  apiUri: `${process.env.REACT_APP_API_URL}`,
  clientId: "jabrapromotionclaim",
  clientSecret: "HsFReDGSgZ8xz63UNL0lhtHxZjsUNW/ETbnjemq+imtZI7WHB6QDbUaTcJ2g5TD4fbN/TjxNFY+Ud+uyCVcw7A==",
  applicationTitle: "Email Test",
  campaignIdentifier: "",
  layoutType: LayoutType.Emailer,
  theme: GT23Theme,
  colours: ColoursGT23,
  learnProps: {
    groupCategories: false
  },
  enableProps: {
    groupByCategory: true,
    searchEnabled: false,
    downloadIcons: false,
    tiles: null
  },
  footerProps: {
    smallPrint: "&copy; Outbound Field Marketing Services",
    helpUrl: "mailto:campaignsupport@out-bound.co.uk?subject=GT23 Contact",
    helpText: "Help",
    privacyUrl: "https://www.scc.com/privacy-notice-employees/",
    privacyText: "Privacy Information",
    termsUrl: "https://sccgt23.blob.core.windows.net/legal/SCC%20GT23%20Incentive%20Terms%20and%20Conditions.pdf",
    termsText: "Terms & Conditions",
    cookiePolicyUrl: null,
    cookiePolicyText: null
  },
  campaignProps: {
    termsButtonText: "SUBMIT",
    termsButtonSubmittingText: "SUBMITTING",
    prizeSection: {
      show: false,
      title: null,
      intro: null,
      dividerImageUrl: null
    },
    dealSummarySections: []
  },
  opportunityManagementProps: {
    vendors: []
  },
  navigationProps: {
    showSalesTools: true,
    showQuiz: true,
    showLeaderboard: false
  }
}