import { useEffect } from 'react';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { toast } from 'react-toastify';
import { apiService } from '../../services';
import { configService } from '../../services';

export const Notifications: React.FC = () => {

  useEffect(() => {
    if (apiService.instance.isLoggedIn()) {
      const connection = new HubConnectionBuilder().withUrl(`${configService.current().apiUri}/notificationhub`, { withCredentials: false, accessTokenFactory: async () => (await apiService.instance.checkToken("")).access_token }).build();

      connection.on('ReceiveMessage', message => {
        toast(message, { type: toast.TYPE.SUCCESS });
      });

      connection.start().then(() => {
        console.log("connect");
      });
    }
  }, []);

  return (
    <></>
  )
}
