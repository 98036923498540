import { useEffect, useState } from "react";
import { getCampaigns, getCampaignsFromAPI } from "./Campaigns.services";
import { AppDispatch, useAppSelector } from "../../store";
import { selectCampaigns } from "../../store/slices/campaigns.slice";
import { CampaignModel } from "outbound-api-client/lib/models";

export const useCampaignDispatch = (
  dispatch: AppDispatch,
  id: string | null = null
) => {

  const campaignsState = useAppSelector(selectCampaigns);
  const campaign: CampaignModel = campaignsState.find(c => c.identifier == id) ?? new CampaignModel();

  useEffect(() => {
    getCampaigns(dispatch)
  }, [dispatch]);

  return {
    campaign,
    hasContent: !!campaignsState.length
  }
}

export const useCampaignsDispatch = (
  dispatch: AppDispatch
) => {

  const campaignsState = useAppSelector(selectCampaigns);

  useEffect(() => {
    getCampaigns(dispatch)
  }, [dispatch]);

  return {
    campaigns: [...campaignsState],
    hasContent: !!campaignsState.length
  }
}

/*export const useCampaign(dispatch: AppDispatch, id: string) => {

  const campaignsState = useAppSelector(selectCampaigns);
  const campaign: CampaignModel = campaignsState.find(c => c.identifier == id) ?? new CampaignModel();

  useEffect(() => {
    getCampaigns(dispatch)
  }, [dispatch]);

  return {
    campaign,
    hasContent: !!campaignsState.length
  }
}*/

export const useCampaigns = () => {
  const [campaigns, setCampaigns] = useState<Array<CampaignModel>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getCampaignsFromAPI()
      .then((campaigns) => {
        setCampaigns(campaigns.filter(c => { return ((!c.dateStart || c.dateStart && c.dateStart <= new Date()) && (!c.dateExpires || c.dateExpires >= new Date()))}));
      })
      .catch((err) => {
        console.error(err);
        setHasError(true);
      })
      .finally(() => {
        setIsLoading(false);
      })
  }, []);

  return {
    campaigns,
    isLoading,
    hasError,
  }
}

export const useCampaign = (id: string) => {
  const [campaign, setCampaign] = useState<CampaignModel>();
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getCampaignsFromAPI(id)
      .then((campaigns) => {
        const campaign = campaigns.find(c => c.identifier === id);
        if (campaign) {
          setCampaign(campaign);
        } else {
          throw new Error(`No campaign found with identifier ${id}`);
        }
      })
      .catch((err) => {
        console.error(err);
        setHasError(true);
      })
      .finally(() => {
        setIsLoading(false);
      })
  }, []);

  return {
    campaign,
    isLoading,
    hasError,
  }
}