import React from 'react';
import { Helmet } from 'react-helmet';
import { useMyTeam } from '../modules/Contact';
import { Row } from '../components/Row';
import { Cell } from '../components/Cell';
import { LoaderContainer } from '../components/Button/Button.styles';
import { Loader } from '../components/Loader';
import { configService } from '../services';

export const Contact: React.FC = () => {
  
  const { myTeam, isLoading } = useMyTeam();

  return (





<div>
      <Helmet>
        <title>{configService.current().applicationTitle} - Contact Us</title>
      </Helmet>
      <Row>
        <Cell className='text-center' baseSize={12}>
          <h2 className="font-title m-bottom-0">Contact Us</h2>
        </Cell>
      </Row>
      <Row style={{marginTop: 15}}>
        <Cell className='text-center' baseSize={12}>
        {isLoading ? <LoaderContainer><Loader secondary /></LoaderContainer> : 
        
        <>
        <h2>{myTeam?.profileData.filter(item => item.key == "accountmanager")[0].value}</h2>
        <div>Partner Account Manager</div>
        <div><a href={`mailto:${myTeam?.profileData.filter(item => item.key == "accountmanageremail")[0].value}`}>{myTeam?.profileData.filter(item => item.key == "accountmanageremail")[0].value}</a></div>
        <div>{myTeam?.profileData.filter(item => item.key == "accountmanagerphone")[0].value}</div>
        </>
        
        }
        </Cell>
      </Row>
      <Row style={{marginTop: 15}}>
        <Cell className='text-center' baseSize={12}>
        <div>For technical enquiries and support help desk, please contact</div>
        <div><a className='font-highlightlink' href="mailto:techsupport@canonpartnerhub.com">techsupport@canonpartnerhub.com</a></div>
        </Cell>
      </Row>
    </div>

  )
}