import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TrainingSet } from '../../modules/Training/Training.types';
import { RootState } from '..';
import { TrainingEpisodeModel } from 'outbound-api-client/lib/models';

type AllTrainingFormats = {
  episodes: TrainingEpisodeModel[];
  collections: TrainingSet[];
}

type TrainingState = AllTrainingFormats & {
  isLoading: boolean;
  isLoaded: boolean;
  hasError: boolean;
}

const initialState: TrainingState = {
  episodes: [],
  collections: [],
  isLoading: false,
  hasError: false,
  isLoaded: false,
};

const trainingSlice = createSlice({
  name: 'training',
  initialState,
  reducers: {
    requestTraining: (state) => {
      return {
        ...state,
        isLoading: true,
        hasError: false,
      }
    },
    requestTrainingSuccess: (state, action: PayloadAction<AllTrainingFormats>) => {
      return {
        ...state,
        episodes: [...action.payload.episodes],
        collections: [...action.payload.collections],
        isLoading: false,
        hasError: false,
        isLoaded: true,
      };
    },
    requestTrainingFailure: (state) => {
      return {
        ...state,
        isLoading: false,
        hasError: true,
        isLoaded: true,
      }
    },
  }
});

export const { requestTraining, requestTrainingSuccess, requestTrainingFailure } = trainingSlice.actions;

export const selectTraining = (state: RootState) => state.training;
export const selectEpisodes = (state: RootState) => state.training.episodes;
export const selectCollections = (state: RootState) => state.training.collections;

export const selectEpisodeByIdentifier = createSelector([
  selectEpisodes,
  (state: RootState, identifier: string) => identifier,
], (episodes, identifier) => {

  return episodes.find(e => e.identifier === identifier)
});

export const selectEpisodeByIndexes = createSelector([
  selectCollections,
  (state: RootState, category: string) => category,
  (state: RootState, _, season: number) => season,
  (state: RootState,_, __, episode: number) => episode,
], (collections, category, season, episode) => {
  const group = collections.find(c => c.category === category);

  if (group) {
    const seasonGroup = group.collection.find(g => g.season === season);

    if (seasonGroup) {
      return seasonGroup.episodes.find(e => e.episodeNumber === episode);
    }
  }

  return undefined;
})

export default trainingSlice.reducer;