import React from 'react';
import { ProgressProps } from './Progress.types';

export const Progress: React.FC<ProgressProps> = ({
  max = 100,
  cssClass = '',
  value,
  secondaryStyle = false,
}) => {
  const css = ['progress-bar', cssClass, secondaryStyle ? 'secondary' : ''].filter(Boolean).join(' ');

  return (
    <progress max={max} value={value} className={css}>
      <div className={`progress-bar${cssClass.length ? ' ' + cssClass : cssClass}`}>
      <span style={{width: `${value}%`}}></span>
      </div>
    </progress>
  )
}