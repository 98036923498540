import React from 'react';
import { Loader } from '..';
import { LoaderContainer } from './Button.styles';
import { ButtonProps } from './Button.type';

export const Button: React.FC<ButtonProps> = ({
  onClick,
  type = 'button',
  primary = true,
  hollow = false,
  cssClass = '',
  children,
  disabled = false,
  isLoading = false,
}) => {
  const classList = `btn ${cssClass} ${primary ? 'btn-primary' : 'btn-secondary'} ${hollow ? 'btn-hollow' : ''} ${disabled ? 'btn-disabled' : ''}`;

  return <button 
    type={type}
    className={classList}
    onClick={onClick}
    disabled={disabled}
  >
    {children}
    {isLoading ? <LoaderContainer><Loader secondary /></LoaderContainer> : null}
  </button>
}