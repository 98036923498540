import React from 'react';
import { FieldProps, getIn } from 'formik';

export const TextAreaInput:React.FC<FieldProps> = ({
  field,
  form: { touched, errors },
}) => {

  const error = getIn(errors, field.name);
  const touch = getIn(touched, field.name);

  return (
    <div>
      <textarea className='font-input' {...field} rows={4} cols={50}  />
      {touch && error && (<div className="error">{error}</div>)}
    </div>
  );
}