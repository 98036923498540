import { TrainingSet } from "./Training.types";
import { TrainingEpisodeModel, ContentUriType, ContentUriModel, TrainingEpisodeDataModel, TrainingEpisodeQuestionDataModel, DetailsModel, TrainingEpisodeResponseDataModel } from "outbound-api-client/lib/models";
import { apiService } from "../../services";
import { AppDispatch } from "../../store";
import { requestTraining, requestTrainingFailure, requestTrainingSuccess } from "../../store/slices/training.slice";

const getTrainingFromAPI = async (campaignIdentifier: string | null = null): Promise<TrainingEpisodeModel[]> => {
  if (apiService.instance.isLoggedIn())
  {
    let result = await apiService.instance.getTrainingEpisodes(campaignIdentifier);
    return result;
  } else {
    return [];
  }
}

const buildTrainingSets = (training: TrainingEpisodeModel[]) => {
  const sets: TrainingSet[] = [];

  const categories = new Set(training.map(t => t.category));

  categories.forEach(category => {
    if (category) {
      const set: TrainingSet = {
        category: category,
        collection: []
      };

      const now = new Date();

      const categorisedTraining = [...training.filter(t => t.category && t.category === category)];

      const seasons = new Set(categorisedTraining.map(t => t.seasonNumber ?? 0));

      seasons.forEach(season => {

        const episodes = [...categorisedTraining.filter(t => t.seasonNumber === season && (t.dateExpires == null || t.dateExpires >= now))];

        episodes.sort((a, b) => {

          let aValue = a.episodeNumber ?? 0;
          let bValue = b.episodeNumber ?? 0;

          return aValue - bValue;
        });

        set.collection.push({
          season,
          episodes,
        });
      });

      set.collection.sort((a, b) => {
        return a.season - b.season;
      });

      sets.push(set);
    }
  });

  return sets;
}

export const getTraining = async (dispatch: AppDispatch, campaignIdentifier: string | null = null) => {
  dispatch(requestTraining());

  try
  {
    const episodes = await getTrainingFromAPI(campaignIdentifier);
    
    const collections = buildTrainingSets(episodes);

    dispatch(requestTrainingSuccess({
      episodes,
      collections,
    }));
  } catch {
    dispatch(requestTrainingFailure());
  }
}

export const GetLetterFromAnswerNumber = (answerNumber: number) => {
  const marker = 'A'.charCodeAt(0) - 1;
  return String.fromCharCode(marker + answerNumber);
}

export const GetTrainingQuestionImage = (contentUris: ContentUriModel[]) => {
  if (contentUris.find(c => c.type === ContentUriType.ThumbnailWide)) {
    const content = contentUris.find(c => c.type === ContentUriType.ThumbnailWide);

    return content?.uri || '';
  }

  if (contentUris.find(c => c.type === ContentUriType.ThumbnailTall)) {
    const content = contentUris.find(c => c.type === ContentUriType.ThumbnailTall);
    
    return content?.uri || '';
  }

  if (contentUris.find(c => c.type === ContentUriType.Image)) {
    const content = contentUris.find(c => c.type === ContentUriType.Image);
    
    return content?.uri || '';
  }

  return '';
}

export const postTrainingResults = async (episodeIdentifier: string, questionData: TrainingEpisodeQuestionDataModel[]): Promise<TrainingEpisodeResponseDataModel> => {
  const trainingData = new TrainingEpisodeDataModel();
  trainingData.trainingEpisodeIdentifier = episodeIdentifier;
  trainingData.questionData = questionData;
  trainingData.dataBag = new Array<string>();

  let returnValue = await apiService.instance.postQuizResponseV2(trainingData, new DetailsModel());

  return returnValue;
}