import React, { useEffect, useState } from 'react';
import { FieldProps } from 'formik';
import { getDateStringForDateField } from '../../services';

export const DateInput:React.FC<FieldProps> = ({
  field,
  form: { touched, errors, setFieldValue },
}) => {
  const [formValue, setFormValue] = useState('');

  useEffect(() => {
    if (field.value) {
      const dateString = getDateStringForDateField(field.value);
      if (dateString !== formValue) {
        setFormValue(`${dateString}`);
      }
    } else {
      setFormValue('');
    }
  }, [field.value]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const date = new Date(e.target.value);

    if (!isNaN(date.valueOf()) && date.getFullYear() > 1000) {
      setFieldValue(field.name, new Date(e.target.value));
    }

    setFormValue(e.target.value);
  }

  return (
    <div>
      <input className='font-input' type='date' {...field} value={formValue} onChange={onChange}  />
      {touched[field.name] && errors[field.name] && (<div className="error">{errors[field.name]}</div>)}
    </div>
  );
}