import React from "react";
import { Card } from "../../../components";
import { FeedItemModel } from "outbound-api-client/lib/models";
import { FeedItemLink } from "./Feed.styles";

export const FeedItem: React.FC<FeedItemModel> = ({
  title,
  subTitle,
  linkToIdentifier,
  contentUris
}) => {
  const headerUri = contentUris.length  ? contentUris[0].uri : '';

  const FeedItemContent = () => {
    return (
      <Card headerImg={headerUri}>
        <h3 className='font-heading'>{title}</h3>
        <p>{subTitle}</p>
      </Card>
    )
  }

  if (linkToIdentifier && linkToIdentifier.length) {
    return (
      <FeedItemLink to={`/article/${linkToIdentifier}`}>
        <FeedItemContent />
      </FeedItemLink>
    )
  } else {
    return <FeedItemContent />
  }
}