import React from "react";
import { faHome, faPhone, faSignInAlt, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Card } from "..";
import { MenuProps } from "./Menu.types";
import { doLogin, doLogout } from '../../hooks';
import { apiService } from '../../services';

export const Menu:React.FC<MenuProps> = ({
  onMenuItemClick
}) => {

  return (
    <Card cssClasses="edge-corners-top">
      <div className="d-flex justify-content-start">
        <div className="text-center p-y-2">
        { ( !apiService.instance.isLoggedIn() ) ? 
        <>
          <div>
            <a className="font-subheading color-text text-decoration-none cursor-pointer" onClick={() => doLogin()}>
              <FontAwesomeIcon size="lg" className="d-block m-x-auto" icon={faSignInAlt} />
              Login/Register
            </a>
          </div>
          <div className="p-y-2">
          <Link to='/' className="font-subheading color-text text-decoration-none" onClick={onMenuItemClick}>
            <FontAwesomeIcon size="lg" className="d-block m-x-auto" icon={faHome} />
            Home
          </Link>
        </div>
        <div>
            <Link to='/contact' className="font-subheading color-text text-decoration-none" onClick={onMenuItemClick}>
              <FontAwesomeIcon size="lg" className="d-block m-x-auto" icon={faPhone} />
              Contact
            </Link>
          </div>
        </>
: <>
    <div>
      <Link to='/' className="font-subheading color-text text-decoration-none" onClick={onMenuItemClick}>
      <FontAwesomeIcon size="lg" className="d-block m-x-auto" icon={faHome} />
      Home
    </Link>
  </div>
  <div className="p-y-2">
            <Link to='/contact' className="font-subheading color-text text-decoration-none" onClick={onMenuItemClick}>
              <FontAwesomeIcon size="lg" className="d-block m-x-auto" icon={faPhone} />
              Contact
            </Link>
          </div>
  </>}
          
          { ( apiService.instance.isLoggedIn() ) ? 
          <div>
            <a className="font-subheading color-text text-decoration-none cursor-pointer" onClick={doLogout}>
              <FontAwesomeIcon size="lg" className="d-block m-x-auto" icon={faSignOutAlt} />
              Logout
            </a>
          </div>
: <></>}
        </div>
      </div>
    </Card>
  )
}