import React from 'react';
import DOMPurify from 'dompurify';
import { ContentProps } from "./Content.types";

export const Content: React.FC<ContentProps> = ({
  template,
}) => {
  const safeHTML = DOMPurify.sanitize(template??"", { ADD_ATTR: ['target'] });

  return(
    <div className='w-100' dangerouslySetInnerHTML={{__html: safeHTML}} />
  );
}