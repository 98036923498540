import { UserScore } from "./Leaderboard.types";
import { apiService } from "../../services";
import { TeamMemberStatusModel } from "outbound-api-client/lib/models";

export const getScores = async (campaignIdentifier = ''): Promise<UserScore[]> => {
  if (apiService.instance.isLoggedIn())
  {
    let leaderboard = await apiService.instance.getLeaderboard(campaignIdentifier);

    let returnValue: UserScore[] = [];
    
    leaderboard.teamMembers.map((teamMember: TeamMemberStatusModel) => {
      let userScore: UserScore = {
        Username: teamMember.user,
        Name: teamMember.displayName,
        CurrentLevel: teamMember.currentLevel,
        Score: teamMember.totalPoints
      };

      returnValue.push(userScore);
    });
    
    return returnValue;
  }
  else
  {
    return [];
  }
}
