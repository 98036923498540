import { useSearchParams } from 'react-router-dom';
import { configService } from '../services';
import { apiService } from '../services';

export const useToken = () => {
  const [searchParams] = useSearchParams();
  
  const getToken = async () => {
        
    let code = "";

    if (searchParams.get("code") !== null && searchParams.get("code") !== "")
    {
        code = searchParams.get("code") as string;
    }

    if (code !== "") {
        await apiService.instance.checkToken(code);
        window.location.href = '/';
    }
  };

  return {
    getToken
  }
}

export const doLogin = (returnUrl = '/connect/authorize') => {
  
  let referrerCode = localStorage.getItem("referrerCode");

  if (referrerCode)
  {
    window.location.href = `${configService.current().apiUri}/account/login/?returnurl=%2Fconnect%2Fauthorize%3Fclient_id%3D${configService.current().clientId}%26redirect_uri%3D${encodeURI(configService.current().appUri)}%2F%26response_type%3Dcode%26scope%3Dopenid%2520profile%2520offline_access%2520roles%2520email%26referrerCode=${referrerCode}`;
  }
  else
  {
    window.location.href = `${configService.current().apiUri}/account/login/?returnurl=%2Fconnect%2Fauthorize%3Fclient_id%3D${configService.current().clientId}%26redirect_uri%3D${encodeURI(configService.current().appUri)}%2F%26response_type%3Dcode%26scope%3Dopenid%2520profile%2520offline_access%2520roles%2520email`;
  }

  
}

export const doLogout = () => {
  window.localStorage.clear();
  window.location.href = '/';
}