import React, { useEffect, useState } from 'react';
import { AnswerProps } from '../Training.types';
import { AnswerBtn } from './Training.styles';

export const Answer: React.FC<AnswerProps> = (props) => {
  const [isSelected, setSelected] = useState(false);

  const onClick = () => {
    setSelected(true);
    props.onSelect();
  }

  useEffect(() => {
    if (!props.questionAnswerd) {
      setSelected(false);
    }
  }, [props.questionAnswerd])
  
  return (
    <AnswerBtn className={isSelected ? 'selected' : undefined} {...props} disabled={isSelected || props.questionAnswerd} onClick={onClick}>{props.text}</AnswerBtn>
  )
}