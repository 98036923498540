import styled from "styled-components";
import { BreakpointOptions, GridTheme } from "../../declarations/styled";
import { splitValueFromUnit } from "../../style/helpers";
import { CellProps } from "./Cell.types";

const calculateFlexValueForBreakpoint = (gridProperties: GridTheme, value: number, breakpoint?: keyof BreakpointOptions) => {
  if(value  > 0 && value <= gridProperties.Columns) {

    const WIDTH = (value/gridProperties.Columns).toString().length > 8 
      ? ((value/gridProperties.Columns) * 100).toFixed(4)
      : ((value/gridProperties.Columns) * 100).toString();

    const style = `
      flex: 0 0 auto;
      width: ${WIDTH}%;
    `;

    if (breakpoint) {
      return `
        @media (min-width: ${gridProperties.Breakpoints[breakpoint]}px){
          ${style}
        }
      `
    } else {
      return style;
    }
  } else {
    return 'flex: 1 0 0%;'
  }
}

export const Cell = styled.div<CellProps>`
  width: 100%;
  max-width: 100%;
  padding-left: ${props => {
    const {value, unit} = splitValueFromUnit(props.theme.Grid.GutterSize);

    return `${value/2}${unit}`;
  }};
  padding-right: ${props => {
    const {value, unit} = splitValueFromUnit(props.theme.Grid.GutterSize);

    return `${value/2}${unit}`;
  }};

  ${props => calculateFlexValueForBreakpoint(props.theme.Grid, props.baseSize || 0)}

  ${props => {
    if(props.SMSize) {
      return calculateFlexValueForBreakpoint(props.theme.Grid, props.SMSize, 'SM');
    }
  }}

  ${props => {
    if(props.MDSize) {
      return calculateFlexValueForBreakpoint(props.theme.Grid, props.MDSize, 'MD');
    }
  }}

  ${props => {
    if(props.LGSize) {
      return calculateFlexValueForBreakpoint(props.theme.Grid, props.LGSize, 'LG');
    }
  }}

  ${props => {
    if(props.XLSize) {
      return calculateFlexValueForBreakpoint(props.theme.Grid, props.XLSize, 'XL');
    }
  }}

  ${props => {
    if(props.MaxSize) {
      return calculateFlexValueForBreakpoint(props.theme.Grid, props.MaxSize, 'MAX');
    }
  }}
`;
