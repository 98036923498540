import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import { configService } from "../../services";
import { PortalProps } from "./Portal.types";

export const Portal: React.FC<PortalProps> = ({ children }) => {
  const mount = document.getElementById("portalRoot");
  const el = document.createElement("div");
                                                                                                                          
  useEffect(() => {
    if (mount) {
      mount.appendChild(el);
      mount.className = configService.current().clientId;
    }

    return () => {
      if (mount)
        mount.removeChild(el);
    }
  }, [el, mount]);

  return createPortal(children, el)
};