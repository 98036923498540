import React, { useEffect, useState } from 'react';
import { QuizHomeProps } from '../Training.types';
import demoVideo from '../../../assets/videos/sample.mp4';
import { ContentUriType, TrainingContentType } from 'outbound-api-client/lib/models';
import { Button, Thumbnail } from '../../../components';
import { apiService } from '../../../services';

export const QuizHome: React.FC<QuizHomeProps> = ({
  contentType,
  title,
  summary,
  contentUris,
  assetViewed,
  triggerAssetViewed,
  goToQuestions,
}) => {
  const [trainingEpisodeEnabled, setTrainingEpisodeEnabled] = useState(false);
  const [currentToken, setCurrentToken] = useState('');

  useEffect(() => {
    if (contentType === TrainingContentType.Other) {
      triggerAssetViewed();
    }

    if (contentType === TrainingContentType.Document) {
      apiService.instance.checkToken('').then((token) => {
        setCurrentToken(currentToken);
      });
    }
  }, []);

  const onAssetClick = () => {
    if (!trainingEpisodeEnabled) {
      setTrainingEpisodeEnabled(true);
      triggerAssetViewed();
    }
  }

  const documentUri = contentUris.find(c => c.type === ContentUriType.Document)?.uri ?? '';

  if(contentType === TrainingContentType.Video) {
    return (
      <div>
        <video controls style={{width: '100%'}} onPlay={onAssetClick}>
          <source src={demoVideo} type="video/mp4"></source>
        </video>
        <div className='d-flex flex-column align-items-center m-top-1'>
          <Button hollow onClick={goToQuestions} disabled={!assetViewed}>TAKE THE QUIZ</Button>
        </div>
      </div>
    )
  }

  if(contentType === TrainingContentType.Document) {
    return (
      <div>
        <Thumbnail contentUris={contentUris} altText={title} />
        <div className='text-center p-top-1'>
          <p>{summary}</p>
          <div className='d-flex flex-column align-items-center'>
            {!!documentUri.length ? (
              <a className='btn btn-secondary' target="_blank" rel="noreferrer" href={documentUri} onClick={onAssetClick}>LEARN</a>
            ) : null}
            <Button hollow onClick={goToQuestions} disabled={!assetViewed || !!!documentUri.length}>TAKE THE QUIZ</Button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      <Thumbnail contentUris={contentUris} altText={title} />
      <div className='text-center p-top-1'>
        <p>{summary}</p>
        <div className='d-flex flex-column align-items-center'>
          <Button primary={false} onClick={goToQuestions}>TAKE THE QUIZ</Button>
        </div>
      </div>
    </div>
  )
}