import React from 'react';
import { HamburgerBase } from './Hamburger.styles';
import { HamburgerProps } from './Hamburger.types';

export const Hamburger: React.FC<HamburgerProps> = ({
  isOpen, onClick
}) => {

  return (
    <HamburgerBase isOpen={isOpen} onClick={onClick}>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </HamburgerBase>
  )
}