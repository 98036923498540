import React from "react";
import { Card, Thumbnail } from "../../../components";
import AssetImage from '../../../assets/images/printer.jpg';
import { QuizLink, TrainingStatus } from "./Training.styles";
import { ContentUriType, TrainingEpisodeModel } from "outbound-api-client/lib/models";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faLock } from "@fortawesome/free-solid-svg-icons";
import { configService, getDateString } from "../../../services";
import { TrainingStatusKey } from "../Training.types";
import { useParams } from "react-router-dom";
//import { RootState, useAppSelector } from "../../../store";
//import { selectEpisodeByIndexes } from "../../../store/slices/training.slice";

export const Training: React.FC<TrainingEpisodeModel> = ({
  identifier,
  category,
  seasonNumber,
  episodeNumber,
  title,
  summary,
  contentUris,
  dateStart,
  isComplete,
  entityPrerequisites
}) => {
  const now = new Date();
  const thumbnails = contentUris.filter(c => c.type === ContentUriType.ThumbnailWide || c.type === ContentUriType.ThumbnailTall);
  const uris = !!thumbnails.length ? [...thumbnails] : [{
    identifier: 'TrainingEpisodeThumbnail',
    uri: AssetImage,
    type: ContentUriType.ThumbnailWide,
  }];
  //const previousEpisode = useAppSelector((state: RootState) => selectEpisodeByIndexes(state, category, (seasonNumber ?? 0), Math.max(0, (episodeNumber ?? 0) - 1)))
  //const previousEpisodeIsAvailable =  typeof episodeNumber === 'undefined' || ((episodeNumber ?? 0) > 1 && previousEpisode && !previousEpisode.isComplete);
  const hasPrerequisites = entityPrerequisites !== null && entityPrerequisites.length > -1;
  const isPrerequisitesBlocking = hasPrerequisites && entityPrerequisites.filter((pr: any) => pr.isSatisfied == false).length > 0;
  const { campaignId } = useParams();

  let trainingStatus: TrainingStatusKey = "open";

  if (isComplete) {
    trainingStatus = "complete";
  }
  else {
    trainingStatus = (dateStart != null && now < dateStart) || (hasPrerequisites && isPrerequisitesBlocking) ? "locked" : "open";
  }

  const dateString = getDateString(dateStart);

  const TrainingCard = () => (
    <Card cssClasses='m-x-0 h-100' contentCSS='p-x-0 p-bottom-05'>
      <div>
        <div className="pos-relative">
          <Thumbnail contentUris={uris} altText={title} cssClass='training-thumbnail' />
          <TrainingStatus status={trainingStatus}>
            {trainingStatus !== 'open' && (
              <div className="text-center">
                {trainingStatus === 'complete' && (
                  <>
                    <FontAwesomeIcon size="2x" color={configService.current().colours.WHITE} icon={faCheck} />
                    <p className="font-subheading m-bottom-0 m-top-05 color-highlight-text">Completed</p>
                  </>
                )}
                {trainingStatus === 'locked' && (
                  <>
                    <FontAwesomeIcon size="2x" color={configService.current().colours.WHITE} icon={faLock} />
                    {(dateStart != null && now < dateStart) ? <p className="font-subheading m-bottom-0 m-top-05 color-highlight-text">Opens {dateString}</p> : <></>}
                  </>
                )}
              </div>
            )}
          </TrainingStatus>
        </div>
        <div className='p-x-1'>
          <h3 className='font-heading'>{title}</h3>
          <p className='font-subheading'>{summary}</p>
        </div>
      </div>
    </Card>);

  if (trainingStatus !== 'locked') {
    if (typeof campaignId == "undefined") {
      return (

        <QuizLink to={`/learn/${identifier}`}>
          <TrainingCard />
        </QuizLink>
      )
    }
    else
    {
      return (

        <QuizLink to={`/incentive/${campaignId}/learn/${identifier}`}>
          <TrainingCard />
        </QuizLink>
      )
    }
  } else {
    return (
      <div className="d-inline-block h-100">
        <TrainingCard />
      </div>
    )
  }
}