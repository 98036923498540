import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Cell, Row, Card, Thumbnail, BidSupportForm } from '../components';
import { TrainingNavButton } from '../modules/Training/components/Training.styles';
import BidSupportBanner from '../assets/images/banner-bidsupport.jpg';
import BidSupportMobileBanner from '../assets/images/banner-bidsupport-mobile.jpg';
import { ContentUriModel, ContentUriType } from 'outbound-api-client/lib/models';
import { configService } from '../services';

const BidSupportUris: ContentUriModel[] = [
  {
    identifier: 'BidSupport',
    uri: BidSupportBanner,
    type: ContentUriType.ThumbnailWide,
  },
  {
    identifier: 'BidSupport-Mobile',
    uri: BidSupportMobileBanner,
    type: ContentUriType.ThumbnailTall,
  }
]

export const BidSupport: React.FC = () => {
  const navigate = useNavigate();

  const onPrevClick = () => {
    navigate(-1);
  }

  return (
    <div>
      <Helmet>
        <title>{configService.current().applicationTitle} - Enable - Bid Support</title>
      </Helmet>
      <div className='p-y-1'>
        <Row>
          <Cell>
            <TrainingNavButton onClick={onPrevClick}>
              <FontAwesomeIcon icon={faChevronLeft} />
              <span className='font-body m-0'>Back</span>
            </TrainingNavButton>
          </Cell>
        </Row>
      </div>
      <Card cssClasses='m-x-0' contentCSS='p-x-0 p-bottom-2'>
        <div className='p-bottom-1'>
          <Thumbnail contentUris={BidSupportUris} altText='Bid Support Tool' />
        </div>
        <div className='p-x-1'>
          <Row direction='column'>
            <Cell baseSize={12}>
              <h2 className='font-title text-center'>Bid Support Tool</h2>
              <p>Get instant bid pricing support for end-user opportunities*. The larger the opportunity the better pricing you’ll receive. Complete the form for a Canon representative to be in touch.</p>
            </Cell>
            <Cell baseSize={12} className='text-center'>
              <BidSupportForm />
            </Cell>
            <p style={{marginTop: 20}}>* Available for single unit Inkjet opportunities and a minimum of 5 laser printer opportunities.</p>
          </Row>
        </div>
      </Card>
    </div>
  )
}