import { SendGridEmailTemplate, EmailSendModel } from 'outbound-api-client/lib/models';
import React, { useEffect, useState } from 'react';
import { Card, LoaderAnimated } from '../../components';
import { EmailTemplate } from './EmailTemplate.component';
import { getEmailTemplates, getExistingSends } from './Emailer.services';
import { EmailTemplateLibraryContainer, ExistingSendsTable } from './Emailer.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';

export const EmailerHome: React.FC = ({
  }) => {

    const [loading, setLoading] = useState(true);
    const [emailTemplates, setEmailTemplates] = useState<Array<SendGridEmailTemplate>>();
    const [existingSends, setExistingSends] = useState<Array<EmailSendModel>>();
    const [ sortedField, setSortedField ] = React.useState<string>("opportunityDate");
    const [ sortedDirection, setSortedDirection ] = React.useState<string>("asc");

        useEffect(() => {
            // declare the data fetching function
            const fetchData = async () => {
              
                getEmailTemplates()
                  .then((emailTemplates) => {
                    setLoading(false);
                    setEmailTemplates(emailTemplates);
                  });

                  getExistingSends()
                  .then((existingSends) => {
                    setLoading(false);
                    setExistingSends(existingSends);
                  });
                  
            }
          
            fetchData()
                .catch(console.error);
            }, [])
         
    return (
        (loading) ? <LoaderAnimated /> : 
        <>
         <div>
            
            <h2>Choose a Template</h2>

                <span>{emailTemplates?.length} templates.</span>
            </div>
            <EmailTemplateLibraryContainer>
                {
                        emailTemplates?.map((template: SendGridEmailTemplate) => {
                            return (<EmailTemplate key={template.templateId ?? ""} templateId={template.templateId ?? ""} name={template.version[0].name} thumbnailUrl={template.version[0].thumbnailUrl} />)
                    })
                }
            </EmailTemplateLibraryContainer>

            <Card cssClasses='m-x-0' contentCSS='p-x-0 p-bottom-2 m-top-2 d-flex justify-content-center'>
              <ExistingSendsTable cellPadding={0} cellSpacing={0}>
                <thead className='text-center'>
                  <tr>
                    <th><span style={{cursor: "pointer"}} onClick={() => { if (sortedField == "name") { setSortedDirection((sortedDirection=="asc")?"desc":"asc") } else { setSortedField("name"); setSortedDirection("asc") }}}>Name</span>{(sortedField=="name") ? (sortedDirection=="asc")?<FontAwesomeIcon icon={faSortUp} style={{color: "#000"}} />:<FontAwesomeIcon icon={faSortDown} style={{color: "#000"}} />:<></>}</th>
                    <th><span style={{cursor: "pointer"}} onClick={() => { if (sortedField == "createdBy") { setSortedDirection((sortedDirection=="asc")?"desc":"asc") } else { setSortedField("createdBy"); setSortedDirection("asc") }}}>Created By</span>{(sortedField=="createdBy") ? (sortedDirection=="asc")?<FontAwesomeIcon icon={faSortUp} style={{color: "#000"}} />:<FontAwesomeIcon icon={faSortDown} style={{color: "#000"}} />:<></>}</th>
                    <th><span style={{cursor: "pointer"}} onClick={() => { if (sortedField == "emailSendRequestStatus") { setSortedDirection((sortedDirection=="asc")?"desc":"asc") } else { setSortedField("emailSendRequestStatus"); setSortedDirection("asc") }}}>Status</span>{(sortedField=="emailSendRequestStatus") ? (sortedDirection=="asc")?<FontAwesomeIcon icon={faSortUp} style={{color: "#000"}} />:<FontAwesomeIcon icon={faSortDown} style={{color: "#000"}} />:<></>}</th>
                  </tr>
                </thead>
                <tbody>
                  {existingSends?.map((o: EmailSendModel, inx: number) => (
                    <tr key={inx} className={`row-${o.identifier}`}>
                      <td className='text-center'>
                        {o.name}
                      </td>
                      <td className='text-center'>
                        {o.createdBy}
                      </td>
                      <td className='text-center'>
                        {o.emailSendRequestStatus}
                      </td>
                    </tr>
                  ))}
                </tbody>

              </ExistingSendsTable>
              </Card>






        </>
    );
}