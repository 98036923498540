import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Row, Cell, LoaderAnimated } from '../components';
import { useUserStatusRefresh } from '../modules';
import { CampaignHeader, useCampaignDispatch } from '../modules/Campaigns';
import { TrainingNavButton } from '../modules/Training/components/Training.styles';
import { useAppDispatch } from '../store';
import { apiService, configService } from '../services';
import { Outlet } from "react-router-dom";
import { CampaignEnrol } from '../modules/Campaigns/CampaignEnrol.component';
import { CampaignEnrolmentValues } from '../modules/Campaigns/CampaignEnrolment.schema';
import { FormikHelpers } from 'formik';
import { InteractionEventType } from 'outbound-api-client/lib/models';
import { LayoutType } from '../services/config.service.types';

export const Incentive: React.FC = () => {

  const { campaignId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { campaign, hasContent } = useCampaignDispatch(dispatch, campaignId || '');
  const isLegacy = configService.current().layoutType == LayoutType.Legacy;
  const [enrolled, setEnrolled] = useState<boolean>(false);
  const [enrolmentChecked, setEnrolChecked] = useState<boolean>(false);

  useUserStatusRefresh(dispatch);

  useEffect(() => {
    if(campaignId){
      apiService.instance.isCampaignEnrolled(campaignId)
        .then((enrolmentStatus) => {
          if (enrolmentStatus && enrolmentStatus.length > 0) {
            setEnrolled(true);
          }
        })
        .finally(() => {
          setEnrolChecked(true);
        })
    }
  }, [enrolled])
  
  const onPrevClick = () => {
    navigate(-1);
  }

  
  const onEnrolSubmit = async (formValues: CampaignEnrolmentValues, formikBag?: FormikHelpers<CampaignEnrolmentValues>) => {
    if (formValues.acceptedTerms) {
      await apiService.postInteraction(InteractionEventType.CampaignEnrol, `Enrol_${campaignId}_EN_GB`, campaignId, campaignId);
      setEnrolled(true);
    }
  }

  return (
    <div>
      <Helmet>
        <title>{configService.current().applicationTitle} - Incentive</title>
      </Helmet>
      {(!isLegacy) ? 
      <div className='p-y-1'>
        <Row>
          <Cell>
            <TrainingNavButton onClick={onPrevClick}>
              <FontAwesomeIcon icon={faChevronLeft} />
              <span className='font-body m-0'>Back</span>
            </TrainingNavButton>
          </Cell>
        </Row>
      </div> : <></>}
      {(!campaign || !hasContent || !enrolmentChecked) ? (
        <LoaderAnimated />
      ) : enrolled ? (<><CampaignHeader /><Outlet /></>) : (
        <><CampaignHeader /><CampaignEnrol {...campaign} onEnrolSubmit={onEnrolSubmit} /></>
      )}
    </div>
  )
}