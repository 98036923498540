import styled from "styled-components";
import { TooltipProps } from "./Trophy.types";

const TrophySizeInEms = 4;
const MarginSizeInEms = 0.125;

export const TrophyWrapper = styled.div`
  position: relative;
  width: ${TrophySizeInEms}em;
  margin: ${MarginSizeInEms}em;
  flex: 0 0 ${TrophySizeInEms}em;
  overflow: visible
`;

export const TrophyImage = styled.img``;

export const TrophyDescription = styled.div<TooltipProps>`
  text-align: center;
  pointer-events: none;
  position: absolute;
  width: 220px;
  top: ${props => props.y}px;
  left: ${props => props.x}px;
  transform: translateX(-50%);
  z-index: 1;
  background: ${props => props.theme.HighlightColour};
  color: ${props => props.theme.HighlightTextColour};
  padding: 1em 0.5em;
  border-radius: ${props => props.theme.Base.BorderRadius};
  transition: all 0.3s ease-in-out;

  &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 6px 6px 6px;
    border-color: transparent transparent ${props => props.theme.HighlightColour} transparent;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const TrophyCollection = styled.div`
  display: flex;
  width: ${(TrophySizeInEms + (MarginSizeInEms*4)) * 4}em;
  margin: 0 auto;
  flex-wrap: wrap;
`