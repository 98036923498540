import React from "react";
import { LoaderProps } from "./Loader.types";

export const Loader: React.FC<LoaderProps> = ({
  primary = true,
  secondary = false,
}) => {

  let modeClass = '';

  if (primary) modeClass = 'primary';
  if (secondary) modeClass = 'secondary';

  return (
    <div className={`loader ${modeClass}`}>
      <div className="spindle-wrapper">
        <div className="spindle" />
        <div className="spindle" />
        <div className="spindle" />
        <div className="spindle" />
        <div className="spindle" />
        <div className="spindle" />
        <div className="spindle" />
        <div className="spindle" />
      </div>
      <div className="d-none">
        Loading&#8230;
      </div>
    </div>
  )
}