import React from 'react';
import { UserPicture } from '..';
import { UserCardWrapper } from './UserCard.styles';
import { UserCardProps } from './UserCard.types';

export const UserCard: React.FC<UserCardProps> = ({
  Name,
  CurrentLevel,
  Highlight = false,
  CssClass = 'm-bottom-05'
}) => {

  return (
    <div className={CssClass}>
      <UserCardWrapper Highlight={Highlight}>
        <div>
          <UserPicture name={Name}  />
        </div>
        <div className='d-flex flex-column justify-content-center'>
          <p className='font-subheading m-0'>{Name}</p>
          <p className='font-smallprint m-0'>{CurrentLevel}</p>
        </div>
      </UserCardWrapper>
    </div>
  )
};