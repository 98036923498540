import React, { useMemo, useCallback } from 'react';
import { configService } from '../../services';
import { UserIcon, UserIconText } from './UserPicture.styles';
import { UserPictureProps } from './UserPicture.types';

export const UserPicture: React.FC<UserPictureProps> = ({ name, cssClass, size = 2.5, useLargerFont = false }) => {

  const hash = useCallback((text: string) => {
    return Math.abs(text.split("").reduce(function(a,b){a=((a<<5)-a)+b.charCodeAt(0);return a&a},0));
  }, []);
  
  const style = useMemo(() => {
    const bgOptions = [configService.current().colours.DARK_TEAL, configService.current().colours.DEEP_AQUA, configService.current().colours.DEEP_BLUE, configService.current().colours.ORANGE, configService.current().colours.AQUA, configService.current().colours.DEEP_ORANGE, configService.current().colours.LILAC];
    const val = hash(name);
    const remainder = val % bgOptions.length;

    return bgOptions[remainder];
  }, [name]);

  const initials = name.split(' ').map(n => n[0]).join('').toUpperCase();

  return (
    <UserIcon background={style} size={size} className={cssClass}>
      <UserIconText useLargerFont={useLargerFont}>
        {initials}
      </UserIconText>
    </UserIcon>
  )
}