import React from 'react';
import { useParams } from 'react-router-dom';
import { useLeaderboard } from '../../modules';
import { LeaderboardContainer } from './Leaderboard.styles';

export const Leaderboard: React.FC = () => {

    const { campaignId } = useParams();
    const { scores } = useLeaderboard(campaignId);

    return (
        <>
            <LeaderboardContainer>
                <table className='leaderboard' cellPadding={0} cellSpacing={0}>
                    <thead className='text-center'>
                        <tr>
                            <th>Rank</th>
                            <th>Name</th>
                            <th>Points</th>
                        </tr>
                    </thead>
                    <tbody>
                        {scores.map((s, inx) => (
                            <tr key={inx}>
                                <td className='text-center'>
                                    {inx + 1}
                                </td>
                                <td>
                                    {s.Name}
                                </td>
                                <td className='text-center'>
                                    {s.Score}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </LeaderboardContainer>       </>
    );
}