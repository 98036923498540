import styled from "styled-components";
import { HamburgerProps } from './Hamburger.types';

export const HamburgerBase = styled.div<HamburgerProps>`
  width: 24px;
  height: 20px;
  position: relative;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;

  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: ${props => props.theme.HighlightTextColour};
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
  }

  span:nth-child(1) {
    ${props => props.isOpen ? `
      top: 18px;
      width: 0%;
      left: 50%;
    ` : `
      top: 0px;
    `}
  }
  
  span:nth-child(4) {
    ${props => props.isOpen ? `
      top: 18px;
      width: 0%;
      left: 50%;
    ` : `
      top: calc(100% - 2px);
    `}
  }
  
  span:nth-child(2), span:nth-child(3) {
    top: calc(50% - 1px);
  }

  ${props => props.isOpen ? `
    span:nth-child(2) {
      transform: rotate(45deg);
    }
    
    span:nth-child(3) {
      transform: rotate(-45deg);
    }
  ` : ``}
`
