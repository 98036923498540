import { InteractionEventType } from 'outbound-api-client/lib/models';
import React, { useEffect, useState, useRef } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
//@ts-ignore
import Winwheel from 'winwheel';
import { apiService, configService } from '../../services';
import { Portal } from '../Portal';
import { SpinTheWheelContainer } from './SpinTheWheel.styles';

declare global {
  interface Window { spinTheWheelComplete: any; }
}

export const SpinTheWheel: React.FC = () => {

  const { campaignId, episodeId, rewardId } = useParams();
  const navigate = useNavigate();
  const [interactionChecked, setInteractionChecked] = useState<boolean>(false);
  const theWheel = useRef<any>(null);

  useEffect(() => {
    // declare the data fetching function
    const fetchData = async () => {
      if(campaignId && episodeId && rewardId && !interactionChecked){
        const username = await apiService.instance.username();
        apiService.instance.getInteractions(InteractionEventType.InstantWinRedeem, campaignId, username, rewardId)
          .then((interactions) => {
            if (interactions.length == 0)
            {
              apiService.postInteraction(InteractionEventType.InstantWinRedeem, rewardId, "", campaignId);
            }
            else
            {
              redirect();
            }
          })
          .finally(() => {
            setInteractionChecked(true);
          })
      }
    }
  
    fetchData()
        .catch(console.error);
    }, [interactionChecked])

  window.spinTheWheelComplete = function()
  {
      // Do basic alert of the segment text.
      if (theWheel.current != null && episodeId)
      {
        setWheelSpinning(false);

        if (theWheel.current.getIndicatedSegment().text == "Win")
        {
          setResult("Win");
          apiService.postInteraction(InteractionEventType.InstantWinSuccess, episodeId, "", campaignId);
        }
        else
        {
          setResult("Lose");
          apiService.postInteraction(InteractionEventType.InstantWinFail, episodeId, "", campaignId);
        }
        
        setShow(true);
      }
  }

  useEffect(() => {
    
    theWheel.current = new Winwheel({
      'numSegments'  : 6,
      'outerRadius'  : 212,
      'centerX'      : 217,
      'centerY'      : 219,
      'textFontSize' : 28, 
      'responsive'   : true,
      'segments'     :   
      [
         {'fillStyle' : '#7df063', 'text' : 'Win'},
         {'fillStyle' : '#f18923', 'text' : 'Lose'},
         {'fillStyle' : '#f18923', 'text' : 'Lose'},
         {'fillStyle' : '#7df063', 'text' : 'Win'},
         {'fillStyle' : '#f18923', 'text' : 'Lose'},
         {'fillStyle' : '#f18923', 'text' : 'Lose'}
      ],
      'animation' :      
      {
          'type'     : 'spinToStop',
          'duration' : 5,
          'spins'    : 8,
          'callbackFinished': 'spinTheWheelComplete()'
      }
  });
  
      
  }, []);

const [wheelSpinning, setWheelSpinning] = useState(false);
const [wheelSpun, setWheelSpun] = useState(false);
const [result, setResult] = useState("");

const redirect = () => {
  navigate(`/incentive/${campaignId}`);
};


const startSpin = () => {
  // Ensure that spinning can't be clicked again while already running.
  if (theWheel.current != null && wheelSpinning === false) {
    resetWheel();

    theWheel.current.startAnimation();
    setWheelSpun(true);
    setWheelSpinning(true);
  }
};

const resetWheel = () => {
  //CSS hiển thị button

  if (theWheel.current != null)
  {
  theWheel.current.stopAnimation(false); // Stop the animation, false as param so does not call callback function.
  theWheel.current.rotationAngle = 0; // Re-set the wheel angle to 0 degrees.
  theWheel.current.draw(); // Call draw to render changes to the wheel.

  setWheelSpinning(false); // Reset to false to power buttons and spin can be clicked again.
  }
};

const [show, setShow] = useState(false);

  return (<>
<div style={{textAlign: "center", marginTop: "10px"}}>
                <SpinTheWheelContainer>
                    <canvas id="canvas" width="434" height="434">
                        <p>Sorry, your browser doesn't support canvas. Please try another.</p>
                    </canvas>
                </SpinTheWheelContainer>

{(!wheelSpun) ?
      <button id="spin_start" className="btn btn-primary" onClick={startSpin}>
        SPIN!
      </button>
      :
      <></>}
      
    </div>
    <Portal>     
  
    <Modal show={show} onHide={redirect} centered>
    <Modal.Header>
        <Modal.Title><div className={configService.current().clientId}>{(result == "Win") ? <p><b>Congratulations, you're a winner!</b></p> : <p><b>Sorry, you haven't won this time ...</b></p>}</div></Modal.Title>
      </Modal.Header>
      <Modal.Body><div className={configService.current().clientId}>{(result == "Win") ? <p>Look out for an Amazon voucher in your inbox within the next few days.</p> : <p>Come back next month for another chance to win. In the meantime, keep registering those deals!</p>}</div></Modal.Body>
      <Modal.Footer>
        <div className={configService.current().clientId}>
        
        <Button variant="primary" onClick={redirect}>
          Done
        </Button>

        </div>
      </Modal.Footer>
    </Modal>

    </Portal></>
  )
}