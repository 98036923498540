import React from 'react';
import { LayoutManagerProps } from './LayoutManager.types';
import { MainZone, GridContainer, ProfileZone, ProfileSummary, LoginTile, Footer, Page } from '../';
import { configService } from '../../services';
import { apiService } from '../../services';
import { ToastContainer } from 'react-toastify';
import { Header, MainNav, Notifications, LegacyHeader } from '../';
import { Outlet } from 'react-router-dom';
import { LayoutType } from '../../services/config.service.types';

const isLoggedIn = apiService.instance.isLoggedIn();

export const LayoutManager: React.FC<LayoutManagerProps> = ({
  children
}) => {

  switch (configService.current().layoutType)
  {
    case LayoutType.Canon:
      return (<Page fixedHeader={false}>
        <GridContainer className='m-x-auto p-top-1'>
          <MainZone>
            <Notifications />
            <ToastContainer autoClose={false} closeOnClick />
            <Header />
            <MainNav />
            <Outlet />
          </MainZone>
          <ProfileZone>
            { isLoggedIn ? (
              <ProfileSummary />
            ) : (
              <LoginTile />
            )}
          </ProfileZone>
      </GridContainer>
      <Footer /></Page>
      );
    break;
    case LayoutType.Emailer:
      return (<Page fixedHeader={false}><GridContainer className='m-x-auto p-top-1'><MainZone><Outlet /><Footer /></MainZone></GridContainer></Page>);
    default:
      return (<Page fixedHeader={false}><LegacyHeader /><Outlet /><Footer /></Page>);
    break;
  }
} 