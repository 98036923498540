import React from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Card } from '../components';
import { useContentTemplate } from '../modules';
import { configService } from '../services';

export const ContentTemplate: React.FC = () => {
  const params = useParams();
  
  const { ContentTemplateContent } = useContentTemplate(params.identifier ?? "");

  return (
    <div>
      <Helmet>
        <title>{configService.current().applicationTitle} - Article</title>
      </Helmet>
      <Card cssClasses='p-x-0 p-bottom-2'>
        <ContentTemplateContent />
      </Card>
    </div>
  )
}