import { RewardModel } from "outbound-api-client/lib/models";
import { apiService } from "../../services";

export const getRewards = async (): Promise<RewardModel[]> => {
  if (apiService.instance.isLoggedIn())
  {
    return apiService.instance.getBadgeRewards("en");
  }
  else
  {
    return [];
  }
}