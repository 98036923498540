import React from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Row, Cell, Card, LoaderAnimated, Leaderboard } from '../components';
import { useCampaign, useContentTemplate } from '../modules';
import { configService } from '../services';

export const LeaderboardV2: React.FC = () => {
  const { campaignId } = useParams();
  const { campaign, hasError, isLoading } = useCampaign(campaignId || '');
  const { ContentTemplateContent } = useContentTemplate("Leaderboard_EN_GB");

  return (
    <div>
      <Helmet>
        <title>{configService.current().applicationTitle} - Incentive - Leaderboard</title>
      </Helmet>
      {(!campaign || isLoading) && !hasError ? (
        <LoaderAnimated />
      ) : campaign ? (
        <Card cssClasses='m-x-0' contentCSS='p-x-0 p-bottom-2'>
          <div>
            <Row direction='column'>
              <Cell baseSize={12}>
              <div className='text-center'>
                  <ContentTemplateContent />
                </div>
              </Cell>
              <Cell baseSize={12} className='text-center'>
                <Leaderboard />
              </Cell>
            </Row>
          </div>
        </Card>
      ) : (
        <div><p>Error</p></div>
      )}
    </div>
  )
}