import React from 'react';
import { Card, LoaderAnimated } from '../../components';
import { CampaignHome } from './CampaignHome.component';

import { useParams } from 'react-router-dom';
import { useCampaignDispatch } from './Campaigns.hooks';
import { useAppDispatch } from '../../store';
import { LogDealActionBar } from '../OpportunityManagement/LogDealActionBar.component';

export const CampaignTemplate: React.FC = () => {

  const dispatch = useAppDispatch();
  const { campaignId } = useParams();
  const { campaign, hasContent } = useCampaignDispatch(dispatch, campaignId || '');
  
  return (
    (!campaign || !hasContent) ? <LoaderAnimated /> : 
    <Card cssClasses='m-x-0' contentCSS='p-x-0 p-bottom-2'>
      <div>
        <>
        <LogDealActionBar />
        <CampaignHome {...campaign} />
        </>
      </div>
    </Card>
  )
}